<template>
  <router-view class="cargo-receiving-background-color"></router-view>
</template>

<script>

export default {
  name: 'cargo-receiving'
}
</script>

<style lang="scss" scoped>
.cargo-receiving-background-color{
  // background-color: #E5E5E5;
  background-color: #f7f7f7;
  height: calc( 100vh - 64px );
  font-family: 'Roboto' !important;
}
</style>
